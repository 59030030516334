import {
	AnyAction,
	createSlice,
	isAnyOf,
	isRejected,
	isRejectedWithValue,
} from '@reduxjs/toolkit';

import { ButtonPropsVariantOverrides } from '@mui/material/Button';
import { OverridableStringUnion } from '@mui/types';
import {
	CHECK_CONNECTION_ERROR,
	ERROR_MESSAGE,
	TOKEN_EXPIRED,
} from 'types/dialogTypes';

interface IDataLimitationsDialog {
	title: string;
	content?: string;
	body: string;
}

interface IState {
	type: string;
	buttonClose: boolean;
	open: boolean;
	title: string;
	content: string;
	eventCancel: boolean;
	eventConfirm: boolean;
	labelCancel: string;
	labelConfirm: string;
	events: boolean;
	multi: boolean;
	data: IDataLimitationsDialog[] | [];
	variantButtonCancel: OverridableStringUnion<
		'text' | 'outlined' | 'contained',
		ButtonPropsVariantOverrides
	>;
}

const initialState: IState = {
	open: false,
	title: '',
	content: '',
	eventCancel: false,
	eventConfirm: false,
	labelCancel: '',
	labelConfirm: '',
	variantButtonCancel: 'outlined',
	events: true,
	multi: false,
	data: [],
	type: '',
	buttonClose: false,
};

const dialogSlice = createSlice({
	name: 'dialog',
	initialState,
	reducers: {
		setDialogOpen: (state, { payload }) => {
			state.open = payload;
		},
		setDialogEventCancel: state => {
			state.eventCancel = true;
			state.open = false;
			state.eventConfirm = false;
			state.title = '';
			state.content = '';
			state.labelCancel = '';
			state.labelConfirm = '';
		},
		setDialogEventConfirm: state => {
			state.eventConfirm = true;
			state.open = false;
			state.eventCancel = false;
			state.title = '';
			state.content = '';
			state.labelCancel = '';
			state.labelConfirm = '';
		},
		setInjectionState: (state, { payload }) => {
			if (payload) {
				state.title = payload.title;
				state.content = payload.content;
				state.labelCancel = payload.labelCancel;
				state.labelConfirm = payload.labelConfirm;
				state.variantButtonCancel = payload.variantButtonCancel;
				state.multi = payload?.multi ? payload.multi : false;
				state.data = payload?.data ? payload.data : [];
				state.type = payload.type;
				state.buttonClose = payload.buttonClose;
			}
		},
		resetDialogConfirm: () => initialState,
	},
	extraReducers: builder => {
		//manage errors popup
		builder.addMatcher(
			isAnyOf(isRejected, isRejectedWithValue),
			(state: any, response: AnyAction) => {
				if (response.meta.aborted) {
					return;
				}

				const errorCode = response.payload?.data?.errorCode;
				switch (errorCode) {
					case '00005':
						return {
							...state,
							title: 'dialog.confirm.error.title',
							content: response.payload?.data?.message
								? response.payload?.data?.message
								: 'dialog.confirm.error.content',
							multi: false,
							buttonClose: true,
							open: true,
							type: TOKEN_EXPIRED,
							labelConfirm: 'commons.buttons.close',
						};
					default:
						switch (response.type) {
							case 'healthCheck/healthCheck/rejected':
								return {
									...state,
									title: 'dialog.confirm.noVpn.title',
									content: response.payload?.data?.message
										? response.payload?.data?.message
										: 'dialog.confirm.noVpn.content',
									multi: false,
									buttonClose: false,
									open: true,
									labelConfirm: 'commons.retry',
									type: CHECK_CONNECTION_ERROR,
								};
							case 'claim/saveClaim/rejected':
								switch (errorCode) {
									case '009':
										return {
											...state,
											title: 'dialog.confirm.visionChangeAlert.title',
											data: [
												{
													title: 'dialog.confirm.visionChangeAlert.subheader',
													content: 'dialog.confirm.visionChangeAlert.content',
												},
											],
											multi: true,
											buttonClose: true,
											open: true,
											type: ERROR_MESSAGE,
										};
									default:
										return {
											...state,
											title: 'dialog.confirm.error.title',
											content: response.payload?.data?.message
												? response.payload?.data?.message
												: 'dialog.confirm.error.content',
											multi: false,
											buttonClose: true,
											open: true,
											type: ERROR_MESSAGE,
										};
								}
							default:
								return {
									...state,
									title: 'dialog.confirm.error.title',
									content: response.payload?.data?.message
										? response.payload?.data?.message
										: 'dialog.confirm.error.content',
									multi: false,
									buttonClose: true,
									open: true,
									type: ERROR_MESSAGE,
									labelConfirm: 'commons.buttons.close',
								};
						}
				}
			},
		);
	},
});

export const {
	setDialogOpen,
	setDialogEventCancel,
	setDialogEventConfirm,
	setInjectionState,
	resetDialogConfirm,
} = dialogSlice.actions;
export default dialogSlice.reducer;
