import {
	resetDialogConfirm,
	setDialogOpen,
	setInjectionState,
} from 'feature/hooks/dialogSlice';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';

import { resetLimitations } from 'feature/limitations/limitationsSlice';
import { VERSION } from 'lodash';
import {
	CHECK_CONNECTION_ERROR,
	GENERIC_ERROR,
	NO_MAT_NO_EXAM,
	NO_PROVIDER,
	SHOW_LIMITATIONS,
	SUBMIT_LATER,
	TOKEN_EXPIRED,
	VISION_CHANGE_ALERT,
} from 'types/dialogTypes';
import { useLogout } from './login.hook';

const useDialogConfirm = () => {
	const dispatch = useDispatch<AppDispatch>();

	const { limitations } = useSelector((state: RootState) => state.limitations);
	const { type } = useSelector((store: RootState) => store.hooks.dialog);

	const mapping = useCallback(
		(type: string, text: string) => {
			switch (type) {
				case SHOW_LIMITATIONS:
					return {
						title: 'dialog.confirm.showLimitations.title',
						content: 'dialog.confirm.showLimitations.content',
						multi: true,
						data: limitations.map(e => ({
							title: e.attributes.title,
							content: e.attributes.body,
						})),
						type: type,
						buttonClose: true,
					};
				case VISION_CHANGE_ALERT:
					//triggered by slice. not configured here
					return {};
				case NO_MAT_NO_EXAM:
					return {
						title: 'dialog.confirm.noMatNoExam.title',
						type: type,
						content: 'dialog.confirm.noMatNoExam.content',
						buttonClose: true,
					};
				case SUBMIT_LATER:
					return {
						title: 'dialog.confirm.submitLater.title',
						content: 'dialog.confirm.submitLater.content',
						labelCancel: 'dialog.buttons.cancel',
						labelConfirm: 'dialog.buttons.continue',
						variantButtonCancel: 'outlined',
						type: type,
						buttonClose: true,
					};
				case GENERIC_ERROR:
					return {
						title: 'dialog.confirm.error.title',
						content: text || 'dialog.confirm.error.content',
						labelCancel: 'dialog.buttons.cancel',
						variantButtonCancel: 'contained',
						type: type,
						buttonClose: true,
					};
				case VERSION:
					return {
						title: 'dialog.version.title',
						content: 'dialog.version.contentTitle',
						type: 'versionModal',
						buttonClose: true,
					};
				default:
			}
		},
		[limitations],
	);

	const handleLogout = useLogout();

	const closeDialog = useCallback(() => {
		dispatch(setDialogOpen(false));
		dispatch(resetDialogConfirm());

		switch (type) {
			case SHOW_LIMITATIONS:
				dispatch(resetLimitations());
				break;
			case TOKEN_EXPIRED:
				handleLogout();
				break;
			default:
				break;
		}
	}, [dispatch, handleLogout, type]);

	const handleOpen = useCallback(
		(type: string, text?: string) => {
			dispatch(setInjectionState(mapping(type, text || '')));
			dispatch(setDialogOpen(true));
		},
		[dispatch, mapping],
	);

	const handleConfirm = useCallback(() => {
		dispatch(resetDialogConfirm());
		switch (type) {
			case NO_PROVIDER:
			case CHECK_CONNECTION_ERROR:
			case TOKEN_EXPIRED:
				handleLogout();
				break;
			default:
				break;
		}
		dispatch(setDialogOpen(false));
	}, [dispatch, handleLogout, type]);

	return useMemo(
		() => ({
			open: handleOpen,
			close: closeDialog,
			confirm: handleConfirm,
		}),
		[closeDialog, handleConfirm, handleOpen],
	);
};

export default useDialogConfirm;
