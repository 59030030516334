export const SHOW_LIMITATIONS = 'showLimitations';
export const COOKIES_DIALOG = 'cookiesDialog';
export const VISION_CHANGE_ALERT = 'visionChangeAlert';
export const NO_MAT_NO_EXAM = 'noMatNoExam';
export const SUBMIT_LATER = 'submitLater';
export const ERROR_MESSAGE = 'errorMessage';
export const NO_PROVIDER = 'noProvider';
export const CHECK_CONNECTION_ERROR = 'noProvider';
export const GENERIC_ERROR = 'genericError';
export const VERSION = 'version';
export const TOKEN_EXPIRED = 'tokenExpired';
